import { red, green, blue } from '@mui/material/colors'

import { ReservationStatus } from 'generated/graphql'

const rowStyle =
    (selectedRow, theme) =>
    (record, index, defaultStyle = {}) => {
        let style = defaultStyle
        if (record) {
            if (selectedRow === record.id) {
                style = {
                    ...style,
                    backgroundColor: theme.palette.action.selected,
                }
            }
            if (record.status === ReservationStatus.WAITING) {
                return {
                    ...style,
                    borderLeftColor: green[500],
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                }
            }
            if (record.status === ReservationStatus.CONFIRM) {
                return {
                    ...style,
                    borderLeftColor: blue[500],
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                }
            }
            if (record.status === ReservationStatus.CANCEL) {
                return {
                    ...style,
                    borderLeftColor: red[500],
                    borderLeftWidth: 5,
                    borderLeftStyle: 'solid',
                }
            }
        }

        return style
    }

export default rowStyle
