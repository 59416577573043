import React, { VFC } from 'react'
import { Divider, Typography, Box } from '@mui/material'
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    DateTimeInput,
    NumberInput,
    Toolbar,
    SaveButton,
    number,
} from 'react-admin'
import { getReservationStatusChoices } from '../../utils'
import { PROGRAM_CHOICES } from 'config'

const ReservationCreateToolbar: VFC = (props) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    )
}

const ReservationCreate: VFC = (props) => {
    const validatePhone = [number('숫자만 입력해주세요.')]

    return (
        <Create {...props} redirect="list" resource="reservations">
            <SimpleForm toolbar={<ReservationCreateToolbar />}>
                <Typography variant="h5">예약 생성</Typography>

                <Divider />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <TextInput label="이름" source="name" helperText="*필수" />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput
                            label="전화번호"
                            source="phone"
                            validate={validatePhone}
                            helperText="*필수"
                        />
                    </Box>
                    <Box sx={{ flex: 1 }} />
                </Box>

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <SelectInput
                            label="예약상태"
                            source="status"
                            defaultValue="WAITING"
                            choices={getReservationStatusChoices()}
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <DateTimeInput
                            label="예약날짜"
                            source="date"
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <SelectInput
                            label="프로그램"
                            source="program"
                            fullWidth
                            choices={PROGRAM_CHOICES}
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="성인체험" source="adult" defaultValue={0} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="아이체험" source="children" defaultValue={0} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="입장만" source="guest" defaultValue={0} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput label="차량" source="car" defaultValue={0} />
                    </Box>
                </Box>
                <br />

                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box sx={{ flex: 1 }}>
                        <NumberInput
                            label="가격"
                            source="price"
                            fullWidth
                            helperText="*필수"
                            sx={{ width: '80%' }}
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput label="입금계좌" source="bankAccount" fullWidth sx={{ width: '80%' }} />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextInput
                            label="환불계좌"
                            source="refundBankAccount"
                            fullWidth
                            sx={{ width: '80%' }}
                        />
                    </Box>
                </Box>
                <br />

                <TextInput label="메모" source="memo" variant="standard" multiline fullWidth />
            </SimpleForm>
        </Create>
    )
}

export default ReservationCreate
