import { queryBuilder as reservations } from './reservations.query'
import { queryBuilder as settings } from './settings.query'

export * from './base.query'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    reservations,
    settings,
}
