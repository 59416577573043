import React, { VFC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'

import { ReservationStatus } from '../../../generated/graphql'

const ReservationStatusFilter: VFC = () => (
    <FilterList label="예약 상태별" icon={<AssignmentTurnedInOutlinedIcon />}>
        <FilterListItem
            label="입금 대기"
            value={{
                status: ReservationStatus.WAITING,
            }}
        ></FilterListItem>
        <FilterListItem
            label="예약 확정"
            value={{
                status: ReservationStatus.CONFIRM,
            }}
        ></FilterListItem>
        <FilterListItem
            label="예약 취소"
            value={{
                status: ReservationStatus.CANCEL,
            }}
        ></FilterListItem>
        <FilterListItem
            label="방문 완료"
            value={{
                status: ReservationStatus.DONE,
            }}
        ></FilterListItem>
    </FilterList>
)

export default ReservationStatusFilter
