import gql from 'graphql-tag'
import { SettingGetDocument, SettingUpdateDocument, SettingUpdateMutationVariables } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
    getOne: {
        query: SettingGetDocument,
    },
    update: {
        query: SettingUpdateDocument,
        variables(id, args): SettingUpdateMutationVariables {
            return {
                input: {
                    ...args,
                },
            }
        },
    },
}

gql`
    fragment SettingFragment on Setting {
        id
        password
        maxDate
        createdAt
        updatedAt
    }

    query SettingGet {
        data: setting {
            ...SettingFragment
        }
    }

    mutation SettingUpdate($input: SettingUpdateInput!) {
        data: settingUpdate(input: $input) {
            data: setting {
                ...SettingFragment
            }
        }
    }
`
