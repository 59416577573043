import React, { VFC } from 'react'
import { AppBar } from 'react-admin'
import { Typography, Box } from '@mui/material'

const CustomAppBar: VFC = (props) => {
    return (
        <AppBar {...props}>
            <Typography variant="h6" color="inherit" id="react-admin-title" sx={{ flex: 1 }} />
            <Typography variant="h6" color="inherit" sx={{ margin: 0, textAlign: 'center' }}>
                모네정원
            </Typography>
            <Box sx={{ flex: 1 }} />
        </AppBar>
    )
}

export default CustomAppBar
