export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'

export const PROGRAM_CHOICES = [
    { id: '(아동)플라워 클래스', name: '(아동)플라워 클래스' },
    { id: '(성인)플라워 클래스', name: '(성인)플라워 클래스' },
    { id: '반려식물 심기', name: '반려식물 심기' },
    { id: '알뿌리 심기', name: '알뿌리 심기' },
    { id: '꼬마농부 고구마 캐기', name: '꼬마농부 고구마 캐기' },
    { id: '감자 캐기', name: '감자 캐기' },
    { id: '매실 체험', name: '매실 체험' },
    { id: '크리스마스 리스', name: '크리스마스 리스' },
    { id: '크리스마스 트리', name: '크리스마스 트리' },
    { id: '할로윈식물 심기', name: '할로윈식물 심기' },
    { id: '일반식물 심기', name: '일반식물 심기' },
    { id: '꽃바구니 만들기', name: '꽃바구니 만들기' },
    { id: '휴일', name: '휴일' },
]
