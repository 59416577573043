import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: Date;
  Object: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type HolyDay = {
  __typename?: 'HolyDay';
  date?: Maybe<Scalars['Date']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  reservationBulkCreate?: Maybe<Array<Maybe<Reservation>>>;
  reservationCreate?: Maybe<ReservationCreatePayload>;
  reservationDeleteMany?: Maybe<ReservationDeleteManyPayload>;
  reservationUpdate?: Maybe<ReservationUpdatePayload>;
  settingUpdate?: Maybe<SettingUpdatePayload>;
};


export type MutationReservationBulkCreateArgs = {
  input: Array<ReservationBulkCreateInput>;
};


export type MutationReservationCreateArgs = {
  input: ReservationCreateInput;
};


export type MutationReservationDeleteManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationReservationUpdateArgs = {
  input: ReservationUpdateInput;
};


export type MutationSettingUpdateArgs = {
  input: SettingUpdateInput;
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Pagination = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  holydays?: Maybe<Array<Maybe<HolyDay>>>;
  login?: Maybe<Scalars['Boolean']>;
  reservation?: Maybe<Reservation>;
  reservationByUID?: Maybe<Reservation>;
  reservations?: Maybe<ReservationConnection>;
  scheduleAvailable?: Maybe<Array<Maybe<Schedule>>>;
  schedules?: Maybe<SchedulesPayload>;
  setting?: Maybe<Setting>;
};


export type QueryLoginArgs = {
  password: Scalars['String'];
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryReservationByUidArgs = {
  uid: Scalars['ID'];
};


export type QueryReservationsArgs = {
  filterBy: ReservationFilter;
  orderBy: ReservationOrder;
  pagination: Pagination;
};


export type QueryScheduleAvailableArgs = {
  date: Scalars['String'];
};

export type Reservation = {
  __typename?: 'Reservation';
  adult?: Maybe<Scalars['Int']>;
  bankAccount?: Maybe<Scalars['String']>;
  car?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  date: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  guest?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  price: Scalars['Int'];
  program: Scalars['String'];
  refundBankAccount?: Maybe<Scalars['String']>;
  status: ReservationStatus;
  uid: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ReservationBulkCreateInput = {
  adult?: InputMaybe<Scalars['Int']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  car?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  guest?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  price: Scalars['Int'];
  program: Scalars['String'];
  refundBankAccount?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ReservationConnection = {
  __typename?: 'ReservationConnection';
  nodes?: Maybe<Array<Reservation>>;
  totalCount: Scalars['Int'];
};

export type ReservationCreateInput = {
  adult?: InputMaybe<Scalars['Int']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  car?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<Scalars['Int']>;
  date: Scalars['Date'];
  guest?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  price: Scalars['Int'];
  program: Scalars['String'];
  refundBankAccount?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ReservationCreatePayload = {
  __typename?: 'ReservationCreatePayload';
  reservation?: Maybe<Reservation>;
};

export type ReservationDeleteManyPayload = {
  __typename?: 'ReservationDeleteManyPayload';
  reservations?: Maybe<Array<Maybe<Reservation>>>;
};

export type ReservationFilter = {
  date?: InputMaybe<Scalars['Date']>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<ReservationPeriod>;
  phone?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReservationStatus>;
};

export type ReservationOrder = {
  direction: OrderDirection;
  field: ReservationOrderField;
};

export enum ReservationOrderField {
  CREATED_AT = 'CREATED_AT',
  DATE = 'DATE',
  ID = 'ID',
  NAME = 'NAME',
  PHONE = 'PHONE',
  PROGRAM = 'PROGRAM'
}

export type ReservationPeriod = {
  end?: InputMaybe<Scalars['Date']>;
  start?: InputMaybe<Scalars['Date']>;
};

export enum ReservationStatus {
  ALL = 'ALL',
  CANCEL = 'CANCEL',
  CONFIRM = 'CONFIRM',
  DONE = 'DONE',
  WAITING = 'WAITING'
}

export type ReservationUpdateInput = {
  adult?: InputMaybe<Scalars['Int']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  car?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  guest?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  program?: InputMaybe<Scalars['String']>;
  refundBankAccount?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ReservationUpdateManyInput = {
  adult?: InputMaybe<Scalars['Int']>;
  bankAccount?: InputMaybe<Scalars['String']>;
  car?: InputMaybe<Scalars['Int']>;
  children?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  guest?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  program?: InputMaybe<Scalars['String']>;
  refundBankAccount?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ReservationUpdatePayload = {
  __typename?: 'ReservationUpdatePayload';
  reservation?: Maybe<Reservation>;
};

export type Schedule = {
  __typename?: 'Schedule';
  date?: Maybe<Scalars['Date']>;
  program?: Maybe<Scalars['String']>;
  totalAdult?: Maybe<Scalars['Int']>;
  totalCar?: Maybe<Scalars['Int']>;
  totalChildren?: Maybe<Scalars['Int']>;
  totalGuest?: Maybe<Scalars['Int']>;
};

export type SchedulesPayload = {
  __typename?: 'SchedulesPayload';
  details?: Maybe<Array<Maybe<Schedule>>>;
  times?: Maybe<Array<Maybe<Schedule>>>;
};

export type Setting = {
  __typename?: 'Setting';
  createdAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  maxDate?: Maybe<Scalars['Date']>;
  password?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SettingUpdateInput = {
  maxDate?: InputMaybe<Scalars['Date']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SettingUpdatePayload = {
  __typename?: 'SettingUpdatePayload';
  setting?: Maybe<Setting>;
};

export type LoginQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type LoginQuery = { __typename?: 'Query', login?: boolean | null };

export type SchedulesQueryVariables = Exact<{ [key: string]: never; }>;


export type SchedulesQuery = { __typename?: 'Query', schedules?: { __typename?: 'SchedulesPayload', times?: Array<{ __typename?: 'Schedule', date?: Date | null, totalCar?: number | null } | null> | null, details?: Array<{ __typename?: 'Schedule', date?: Date | null, program?: string | null, totalAdult?: number | null, totalChildren?: number | null, totalGuest?: number | null, totalCar?: number | null } | null> | null } | null };

export type ReservationFragmentFragment = { __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null };

export type ReservationGetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReservationGetQuery = { __typename?: 'Query', data?: { __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null } | null };

export type ReservationGetListQueryVariables = Exact<{
  filterBy: ReservationFilter;
  orderBy: ReservationOrder;
  pagination: Pagination;
}>;


export type ReservationGetListQuery = { __typename?: 'Query', data?: { __typename?: 'ReservationConnection', total: number, data?: Array<{ __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null }> | null } | null };

export type ReservationCreateMutationVariables = Exact<{
  input: ReservationCreateInput;
}>;


export type ReservationCreateMutation = { __typename?: 'Mutation', data?: { __typename?: 'ReservationCreatePayload', data?: { __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null } | null } | null };

export type ReservationUpdateMutationVariables = Exact<{
  input: ReservationUpdateInput;
}>;


export type ReservationUpdateMutation = { __typename?: 'Mutation', data?: { __typename?: 'ReservationUpdatePayload', data?: { __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null } | null } | null };

export type ReservationDeleteManyMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReservationDeleteManyMutation = { __typename?: 'Mutation', data?: { __typename?: 'ReservationDeleteManyPayload', data?: Array<{ __typename?: 'Reservation', id: string, uid: string, name: string, phone: string, date: Date, program: string, price: number, status: ReservationStatus, bankAccount?: string | null, refundBankAccount?: string | null, adult?: number | null, children?: number | null, guest?: number | null, car?: number | null, memo?: string | null, createdAt: Date, updatedAt?: Date | null } | null> | null } | null };

export type SettingFragmentFragment = { __typename?: 'Setting', id?: number | null, password?: string | null, maxDate?: Date | null, createdAt?: Date | null, updatedAt?: Date | null };

export type SettingGetQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingGetQuery = { __typename?: 'Query', data?: { __typename?: 'Setting', id?: number | null, password?: string | null, maxDate?: Date | null, createdAt?: Date | null, updatedAt?: Date | null } | null };

export type SettingUpdateMutationVariables = Exact<{
  input: SettingUpdateInput;
}>;


export type SettingUpdateMutation = { __typename?: 'Mutation', data?: { __typename?: 'SettingUpdatePayload', data?: { __typename?: 'Setting', id?: number | null, password?: string | null, maxDate?: Date | null, createdAt?: Date | null, updatedAt?: Date | null } | null } | null };

export const ReservationFragmentFragmentDoc = gql`
    fragment ReservationFragment on Reservation {
  id
  uid
  name
  phone
  date
  program
  price
  status
  bankAccount
  refundBankAccount
  adult
  children
  guest
  car
  memo
  createdAt
  updatedAt
}
    `;
export const SettingFragmentFragmentDoc = gql`
    fragment SettingFragment on Setting {
  id
  password
  maxDate
  createdAt
  updatedAt
}
    `;
export const LoginDocument = gql`
    query Login($password: String!) {
  login(password: $password)
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const SchedulesDocument = gql`
    query Schedules {
  schedules {
    times {
      date
      totalCar
    }
    details {
      date
      program
      totalAdult
      totalChildren
      totalGuest
      totalCar
    }
  }
}
    `;

/**
 * __useSchedulesQuery__
 *
 * To run a query within a React component, call `useSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<SchedulesQuery, SchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulesQuery, SchedulesQueryVariables>(SchedulesDocument, options);
      }
export function useSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulesQuery, SchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulesQuery, SchedulesQueryVariables>(SchedulesDocument, options);
        }
export type SchedulesQueryHookResult = ReturnType<typeof useSchedulesQuery>;
export type SchedulesLazyQueryHookResult = ReturnType<typeof useSchedulesLazyQuery>;
export type SchedulesQueryResult = Apollo.QueryResult<SchedulesQuery, SchedulesQueryVariables>;
export const ReservationGetDocument = gql`
    query ReservationGet($id: ID!) {
  data: reservation(id: $id) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;

/**
 * __useReservationGetQuery__
 *
 * To run a query within a React component, call `useReservationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReservationGetQuery(baseOptions: Apollo.QueryHookOptions<ReservationGetQuery, ReservationGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationGetQuery, ReservationGetQueryVariables>(ReservationGetDocument, options);
      }
export function useReservationGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationGetQuery, ReservationGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationGetQuery, ReservationGetQueryVariables>(ReservationGetDocument, options);
        }
export type ReservationGetQueryHookResult = ReturnType<typeof useReservationGetQuery>;
export type ReservationGetLazyQueryHookResult = ReturnType<typeof useReservationGetLazyQuery>;
export type ReservationGetQueryResult = Apollo.QueryResult<ReservationGetQuery, ReservationGetQueryVariables>;
export const ReservationGetListDocument = gql`
    query ReservationGetList($filterBy: ReservationFilter!, $orderBy: ReservationOrder!, $pagination: Pagination!) {
  data: reservations(
    filterBy: $filterBy
    orderBy: $orderBy
    pagination: $pagination
  ) {
    data: nodes {
      id
      ...ReservationFragment
    }
    total: totalCount
  }
}
    ${ReservationFragmentFragmentDoc}`;

/**
 * __useReservationGetListQuery__
 *
 * To run a query within a React component, call `useReservationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useReservationGetListQuery(baseOptions: Apollo.QueryHookOptions<ReservationGetListQuery, ReservationGetListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationGetListQuery, ReservationGetListQueryVariables>(ReservationGetListDocument, options);
      }
export function useReservationGetListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationGetListQuery, ReservationGetListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationGetListQuery, ReservationGetListQueryVariables>(ReservationGetListDocument, options);
        }
export type ReservationGetListQueryHookResult = ReturnType<typeof useReservationGetListQuery>;
export type ReservationGetListLazyQueryHookResult = ReturnType<typeof useReservationGetListLazyQuery>;
export type ReservationGetListQueryResult = Apollo.QueryResult<ReservationGetListQuery, ReservationGetListQueryVariables>;
export const ReservationCreateDocument = gql`
    mutation ReservationCreate($input: ReservationCreateInput!) {
  data: reservationCreate(input: $input) {
    data: reservation {
      ...ReservationFragment
    }
  }
}
    ${ReservationFragmentFragmentDoc}`;
export type ReservationCreateMutationFn = Apollo.MutationFunction<ReservationCreateMutation, ReservationCreateMutationVariables>;

/**
 * __useReservationCreateMutation__
 *
 * To run a mutation, you first call `useReservationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReservationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reservationCreateMutation, { data, loading, error }] = useReservationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReservationCreateMutation(baseOptions?: Apollo.MutationHookOptions<ReservationCreateMutation, ReservationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReservationCreateMutation, ReservationCreateMutationVariables>(ReservationCreateDocument, options);
      }
export type ReservationCreateMutationHookResult = ReturnType<typeof useReservationCreateMutation>;
export type ReservationCreateMutationResult = Apollo.MutationResult<ReservationCreateMutation>;
export type ReservationCreateMutationOptions = Apollo.BaseMutationOptions<ReservationCreateMutation, ReservationCreateMutationVariables>;
export const ReservationUpdateDocument = gql`
    mutation ReservationUpdate($input: ReservationUpdateInput!) {
  data: reservationUpdate(input: $input) {
    data: reservation {
      ...ReservationFragment
    }
  }
}
    ${ReservationFragmentFragmentDoc}`;
export type ReservationUpdateMutationFn = Apollo.MutationFunction<ReservationUpdateMutation, ReservationUpdateMutationVariables>;

/**
 * __useReservationUpdateMutation__
 *
 * To run a mutation, you first call `useReservationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReservationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reservationUpdateMutation, { data, loading, error }] = useReservationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReservationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ReservationUpdateMutation, ReservationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReservationUpdateMutation, ReservationUpdateMutationVariables>(ReservationUpdateDocument, options);
      }
export type ReservationUpdateMutationHookResult = ReturnType<typeof useReservationUpdateMutation>;
export type ReservationUpdateMutationResult = Apollo.MutationResult<ReservationUpdateMutation>;
export type ReservationUpdateMutationOptions = Apollo.BaseMutationOptions<ReservationUpdateMutation, ReservationUpdateMutationVariables>;
export const ReservationDeleteManyDocument = gql`
    mutation ReservationDeleteMany($ids: [ID!]!) {
  data: reservationDeleteMany(ids: $ids) {
    data: reservations {
      ...ReservationFragment
    }
  }
}
    ${ReservationFragmentFragmentDoc}`;
export type ReservationDeleteManyMutationFn = Apollo.MutationFunction<ReservationDeleteManyMutation, ReservationDeleteManyMutationVariables>;

/**
 * __useReservationDeleteManyMutation__
 *
 * To run a mutation, you first call `useReservationDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReservationDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reservationDeleteManyMutation, { data, loading, error }] = useReservationDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReservationDeleteManyMutation(baseOptions?: Apollo.MutationHookOptions<ReservationDeleteManyMutation, ReservationDeleteManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReservationDeleteManyMutation, ReservationDeleteManyMutationVariables>(ReservationDeleteManyDocument, options);
      }
export type ReservationDeleteManyMutationHookResult = ReturnType<typeof useReservationDeleteManyMutation>;
export type ReservationDeleteManyMutationResult = Apollo.MutationResult<ReservationDeleteManyMutation>;
export type ReservationDeleteManyMutationOptions = Apollo.BaseMutationOptions<ReservationDeleteManyMutation, ReservationDeleteManyMutationVariables>;
export const SettingGetDocument = gql`
    query SettingGet {
  data: setting {
    ...SettingFragment
  }
}
    ${SettingFragmentFragmentDoc}`;

/**
 * __useSettingGetQuery__
 *
 * To run a query within a React component, call `useSettingGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingGetQuery(baseOptions?: Apollo.QueryHookOptions<SettingGetQuery, SettingGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingGetQuery, SettingGetQueryVariables>(SettingGetDocument, options);
      }
export function useSettingGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingGetQuery, SettingGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingGetQuery, SettingGetQueryVariables>(SettingGetDocument, options);
        }
export type SettingGetQueryHookResult = ReturnType<typeof useSettingGetQuery>;
export type SettingGetLazyQueryHookResult = ReturnType<typeof useSettingGetLazyQuery>;
export type SettingGetQueryResult = Apollo.QueryResult<SettingGetQuery, SettingGetQueryVariables>;
export const SettingUpdateDocument = gql`
    mutation SettingUpdate($input: SettingUpdateInput!) {
  data: settingUpdate(input: $input) {
    data: setting {
      ...SettingFragment
    }
  }
}
    ${SettingFragmentFragmentDoc}`;
export type SettingUpdateMutationFn = Apollo.MutationFunction<SettingUpdateMutation, SettingUpdateMutationVariables>;

/**
 * __useSettingUpdateMutation__
 *
 * To run a mutation, you first call `useSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingUpdateMutation, { data, loading, error }] = useSettingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SettingUpdateMutation, SettingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettingUpdateMutation, SettingUpdateMutationVariables>(SettingUpdateDocument, options);
      }
export type SettingUpdateMutationHookResult = ReturnType<typeof useSettingUpdateMutation>;
export type SettingUpdateMutationResult = Apollo.MutationResult<SettingUpdateMutation>;
export type SettingUpdateMutationOptions = Apollo.BaseMutationOptions<SettingUpdateMutation, SettingUpdateMutationVariables>;