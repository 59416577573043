import gql from 'graphql-tag'
import {
    ReservationGetDocument,
    ReservationGetListDocument,
    ReservationCreateDocument,
    ReservationCreateMutationVariables,
    ReservationUpdateDocument,
    ReservationUpdateMutationVariables,
    ReservationDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export const queryBuilder: QueryBuilder = {
    getOne: {
        query: ReservationGetDocument,
    },
    getList: {
        query: ReservationGetListDocument,
        parseResponse: (response) => {
            const { data, total } = response.data
            return {
                total,
                data,
            }
        },
    },
    create: {
        query: ReservationCreateDocument,
        variables(args): ReservationCreateMutationVariables {
            return {
                input: {
                    ...args,
                },
            }
        },
        input(params) {
            /* eslint-disable */
            const { createdAt, updatedAt, deletedAt, __typename, ...other } = params
            return other
        },
    },
    update: {
        query: ReservationUpdateDocument,
        variables(id, args): ReservationUpdateMutationVariables {
            return {
                input: {
                    id,
                    ...args,
                },
            }
        },
    },
    deleteMany: {
        query: ReservationDeleteManyDocument,
    },
}

gql`
    fragment ReservationFragment on Reservation {
        id
        uid
        name
        phone
        date
        program
        price
        status
        bankAccount
        refundBankAccount
        adult
        children
        guest
        car
        memo
        createdAt
        updatedAt
    }

    query ReservationGet($id: ID!) {
        data: reservation(id: $id) {
            ...ReservationFragment
        }
    }

    query ReservationGetList(
        $filterBy: ReservationFilter!
        $orderBy: ReservationOrder!
        $pagination: Pagination!
    ) {
        data: reservations(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
            data: nodes {
                id
                ...ReservationFragment
            }
            total: totalCount
        }
    }

    mutation ReservationCreate($input: ReservationCreateInput!) {
        data: reservationCreate(input: $input) {
            data: reservation {
                ...ReservationFragment
            }
        }
    }

    mutation ReservationUpdate($input: ReservationUpdateInput!) {
        data: reservationUpdate(input: $input) {
            data: reservation {
                ...ReservationFragment
            }
        }
    }

    mutation ReservationDeleteMany($ids: [ID!]!) {
        data: reservationDeleteMany(ids: $ids) {
            data: reservations {
                ...ReservationFragment
            }
        }
    }
`
