import React, { VFC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import {
    endOfYesterday,
    endOfTomorrow,
    endOfToday,
    startOfWeek,
    endOfWeek,
    addWeeks,
    subWeeks,
    startOfMonth,
    endOfMonth,
    addMonths,
    subMonths,
    format,
} from 'date-fns'

const ReservationPeriodFilter: VFC = () => (
    <FilterList label="기간별" icon={<AccessTimeOutlinedIcon />}>
        <FilterListItem
            label="내일"
            value={{
                period: {
                    start: format(endOfTomorrow(), 'yyyy-MM-dd'),
                    end: format(endOfTomorrow(), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="오늘"
            value={{
                period: {
                    start: format(endOfToday(), 'yyyy-MM-dd'),
                    end: format(endOfToday(), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="어제"
            value={{
                period: {
                    start: format(endOfYesterday(), 'yyyy-MM-dd'),
                    end: format(endOfYesterday(), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="다음주"
            value={{
                period: {
                    start: format(addWeeks(startOfWeek(new Date()), 1), 'yyyy-MM-dd'),
                    end: format(addWeeks(endOfWeek(new Date()), 1), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="이번주"
            value={{
                period: {
                    start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
                    end: format(endOfWeek(new Date()), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="지난주"
            value={{
                period: {
                    start: format(subWeeks(startOfWeek(new Date()), 1), 'yyyy-MM-dd'),
                    end: format(subWeeks(endOfWeek(new Date()), 1), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="다음달"
            value={{
                period: {
                    start: format(startOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd'),
                    end: format(endOfMonth(addMonths(new Date(), 1)), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="이번달"
            value={{
                period: {
                    start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
                    end: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
                },
            }}
        />
        <FilterListItem
            label="지난달"
            value={{
                period: {
                    start: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
                    end: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
                },
            }}
        />
    </FilterList>
)

export default ReservationPeriodFilter
