import React, { VFC } from 'react'
import { TextField, DateField, Show, SimpleShowLayout } from 'react-admin'

const CoverLetterShow: VFC = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source={'id' as any} />
                <TextField source={'name' as any} />
                <TextField source={'id' as any} />
                <br />

                <TextField source={'program' as any} />
                <TextField source={'price' as any} />
                <DateField source={'date' as any} />
                <br />

                <TextField source={'status' as any} />
                <TextField source={'bankAccount' as any} />
                <TextField source={'refundBankAccount' as any} />
                <br />

                <TextField source={'adult' as any} />
                <TextField source={'children' as any} />
                <TextField source={'car' as any} />
                <br />

                <DateField source={'createdAt' as any} showTime />
                <DateField source={'updatedAt' as any} showTime />
                <br />
            </SimpleShowLayout>
        </Show>
    )
}

export default CoverLetterShow
