import React, { VFC } from 'react'
import { Layout as ReactAdminLayout } from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'

const Layout: VFC = (props) => {
    return <ReactAdminLayout {...props} appBar={AppBar} menu={Menu} />
}

export default Layout
