import React, { VFC, useEffect, useState } from 'react'
import gql from 'graphql-tag'

import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar'

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { Container, Paper } from '@mui/material'

import koLocale from 'date-fns/locale/ko'
import { addMinutes } from 'date-fns'

import 'react-big-calendar/lib/css/react-big-calendar.css'

import { useSchedulesLazyQuery } from 'generated/graphql'

const MIN_TIME = new Date(0, 0, 0, 10, 30, 0)
const MAX_TIME = new Date(0, 0, 0, 18, 0, 0)

const locales = {
    'ko-KR': koLocale,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const Dashboard: VFC = () => {
    const [schedules, { data }] = useSchedulesLazyQuery()
    const [events, setEvents] = useState([])

    useEffect(() => {
        schedules()

        if (data) {
            const schedule = (data.schedules?.times as any).map((schedule) => {
                const details = data.schedules?.details?.filter((detail) => detail?.date === schedule.date)

                return {
                    start: new Date(schedule.date),
                    end: addMinutes(schedule.date, 120),
                    title: `${schedule.totalCar}차량`,
                    details,
                }
            })
            setEvents(schedule)
        }
    }, [data, schedules])

    const handleSelectEvent = (event) => {
        const text = event.details.map((detail) => {
            return `${detail.program}: 성인 ${detail.totalAdult}, 아동: ${detail.totalChildren}, 방문: ${detail.totalGuest}, 차량: ${detail.totalCar}`
        })

        alert(text.join('\n'))
    }

    return (
        <Container style={{ height: '100%', paddingLeft: 0, paddingRight: 0 }}>
            <Paper style={{ height: 50 }}></Paper>
            <Paper style={{ minHeight: 500, height: '100%' }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView={Views.WEEK}
                    timeslots={3}
                    views={['month', 'week', 'day']}
                    style={{ height: 700 }}
                    min={MIN_TIME}
                    max={MAX_TIME}
                    onSelectEvent={handleSelectEvent}
                />
            </Paper>
        </Container>
    )
}

export default Dashboard

gql`
    query Schedules {
        schedules {
            times {
                date
                totalCar
            }
            details {
                date
                program
                totalAdult
                totalChildren
                totalGuest
                totalCar
            }
        }
    }
`
