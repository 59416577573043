import { ApolloClient, InMemoryCache } from '@apollo/client'
import { API_BASE_URL } from './config'
import { GraphQLClient } from './dataProvider'

export const apolloClient = new ApolloClient({
    connectToDevTools: true,
    uri: `${API_BASE_URL}/graphql`,
    cache: new InMemoryCache().restore({}),
})

export const graphqlClient: GraphQLClient = {
    query: async (query: any, variables: any) => {
        const result = await apolloClient.query({
            query,
            fetchPolicy: 'network-only',
            variables,
        })

        if (result.errors && result.errors.length > 0) {
            throw result.errors
        }

        return result
    },
    mutate: async (mutation: any, variables: any) => {
        const result = await apolloClient.mutate({
            mutation,
            variables,
        })

        // if (result.errors && result.errors.length > 0) {
        //   throw result.errors
        // }

        return result
    },
}
