import React, { VFC } from 'react'
import { MenuItemLink } from 'react-admin'

import DashboardIcon from '@mui/icons-material/Dashboard'
import GroupIcon from '@mui/icons-material/Group'
import SettingsIcon from '@mui/icons-material/Settings'

interface MenuProps {
    dense?: boolean | undefined
}

const Menu: VFC<MenuProps> = ({ dense }) => {
    return (
        <>
            <MenuItemLink
                sidebarIsOpen
                to="/"
                leftIcon={<DashboardIcon />}
                primaryText="대시보드"
                dense={dense}
            />
            <MenuItemLink
                sidebarIsOpen
                to="/reservations"
                leftIcon={<GroupIcon />}
                primaryText="예약 관리"
                dense={dense}
            />
            <MenuItemLink
                sidebarIsOpen
                to="/settings/1"
                leftIcon={<SettingsIcon />}
                primaryText="설정"
                dense={dense}
            />
        </>
    )
}

export default Menu
